@tailwind utilities;

.pypestream-loader > div {
    text-align: center;
    color: white;
    height: 8rem;
    width: 8rem;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  
  .pypestream-loader > div > div:last-child {
    height: 72px;
    width: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: auto;
    position: absolute;
    top: -36px;
    left: 0;
    bottom: 0;
    right: 0;
  }
  
  .pypestream-loader > div > div:last-child path:last-child {
    fill: white;
  }
  