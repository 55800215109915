/////// Colors ////////
// Background
$backgroundBlack: #000000;
$backgroundGrey200: #f3f3f3;
$backgroundGrey300: #dddddd;
$backgroundGrey400: #bbbbbb;
$backgroundGrey500: #8e8e8e;
$backgroundGrey600: #7a6769;

// Interaction
$interactionPrimary100: #cbdffb;
$interactionPrimary200: #99bdf7;
$interactionPrimary500: #0a3ab4;

// Error
$otherError500: #e45f4f;

/////// Typography ////////
$mainFont: "Open Sans";
$text-primary: #29302e;
$text-gray: #7a6769;
$color-primary: #0a3ab4;
$color-primary-600: #062d9a;
$color-primary-700: #042181;
$color-placeholder: #716f6f;
