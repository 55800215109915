.visible {
  visibility: visible;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
}

.inset-0 {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.top-0 {
  top: 0px;
}

.top-1 {
  top: 0.25rem;
}

.top-1\.5 {
  top: 0.375rem;
}

.-top-3 {
  top: -0.75rem;
}

.-top-8 {
  top: -2rem;
}

.bottom-0 {
  bottom: 0px;
}

.left-2\/4 {
  left: 50%;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.float-left {
  float: left;
}

.m-0 {
  margin: 0px;
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.my-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.my-3\.5 {
  margin-top: 0.875rem;
  margin-bottom: 0.875rem;
}

.mt-0 {
  margin-top: 0px;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mt-2\.5 {
  margin-top: 0.625rem;
}

.-mt-50 {
  margin-top: -50px;
}

.mr-0 {
  margin-right: 0px;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-3 {
  margin-right: 0.75rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mr-14 {
  margin-right: 3.5rem;
}

.mr-16 {
  margin-right: 4rem;
}

.mr-1\.5 {
  margin-right: 0.375rem;
}

.-mr-4 {
  margin-right: -1rem;
}

.mb-0 {
  margin-bottom: 0px;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-14 {
  margin-bottom: 3.5rem;
}

.ml-1 {
  margin-left: 0.25rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-auto {
  margin-left: auto;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-4 {
  height: 1rem;
}

.h-6 {
  height: 1.5rem;
}

.h-8 {
  height: 2rem;
}

.h-9 {
  height: 2.25rem;
}

.h-12 {
  height: 3rem;
}

.h-14 {
  height: 3.5rem;
}

.h-24 {
  height: 6rem;
}

.h-96 {
  height: 24rem;
}

.h-full {
  height: 100%;
}

.h-graph {
  height: calc(100vh - 80px);
}

.max-h-full {
  max-height: 100%;
}

.w-6 {
  width: 1.5rem;
}

.w-8 {
  width: 2rem;
}

.w-12 {
  width: 3rem;
}

.w-14 {
  width: 3.5rem;
}

.w-24 {
  width: 6rem;
}

.w-60 {
  width: 15rem;
}

.w-200 {
  width: 200px;
}

.w-312 {
  width: 312px;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.333333%;
}

.w-6\/12 {
  width: 50%;
}

.w-full {
  width: 100%;
}

.w-min {
  width: -webkit-min-content;
  width: min-content;
}

.max-w-4xl {
  max-width: 56rem;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-grow {
  flex-grow: 1;
}

.transform {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  -webkit-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:scale-110:hover {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes ping {
  75%, 100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0;
  }
}

@keyframes ping {
  75%, 100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0;
  }
}

@-webkit-keyframes pulse {
  50% {
    opacity: .5;
  }
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

@-webkit-keyframes bounce {
  0%, 100% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50% {
    -webkit-transform: none;
            transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

@keyframes bounce {
  0%, 100% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50% {
    -webkit-transform: none;
            transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

.cursor-pointer {
  cursor: pointer;
}

.resize {
  resize: both;
}

.grid-flow-row {
  grid-auto-flow: row;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded-sm {
  border-radius: 0.125rem;
}

.rounded {
  border-radius: 0.25rem;
}

.rounded-md {
  border-radius: 0.375rem;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-t {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.rounded-b {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.rounded-bl {
  border-bottom-left-radius: 0.25rem;
}

.first\:rounded-tl:first-child {
  border-top-left-radius: 0.25rem;
}

.first\:rounded-bl:first-child {
  border-bottom-left-radius: 0.25rem;
}

.last\:rounded-tr:last-child {
  border-top-right-radius: 0.25rem;
}

.last\:rounded-br:last-child {
  border-bottom-right-radius: 0.25rem;
}

.border-0 {
  border-width: 0px;
}

.border {
  border-width: 1px;
}

.border-t-0 {
  border-top-width: 0px;
}

.border-r-0 {
  border-right-width: 0px;
}

.border-r-4 {
  border-right-width: 4px;
}

.border-r {
  border-right-width: 1px;
}

.border-b-0 {
  border-bottom-width: 0px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-l-0 {
  border-left-width: 0px;
}

.border-solid {
  border-style: solid;
}

.border-transparent {
  border-color: transparent;
}

.border-color-primary {
  --tw-border-opacity: 1;
  border-color: rgba(221, 221, 221, var(--tw-border-opacity));
}

.border-bg-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgba(187, 187, 187, var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(221, 221, 221, var(--tw-border-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
}

.bg-red-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
}

.bg-yellow-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
}

.bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
}

.object-cover {
  object-fit: cover;
}

.p-0 {
  padding: 0px;
}

.p-4 {
  padding: 1rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-10 {
  padding: 2.5rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pb-0 {
  padding-bottom: 0px;
}

.pb-3 {
  padding-bottom: 0.75rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pl-0 {
  padding-left: 0px;
}

.pl-6 {
  padding-left: 1.5rem;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.capitalize {
  text-transform: capitalize;
}

.italic {
  font-style: italic;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}

.hover\:text-blue-800:hover {
  --tw-text-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-text-opacity));
}

*, ::before, ::after {
  --tw-shadow: 0 0 #0000;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-lg:hover {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

*, ::before, ::after {
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-gray-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
}

.filter {
  --tw-blur: var(--tw-empty,/*!*/ /*!*/);
  --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
  --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
  --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
  --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
  --tw-invert: var(--tw-empty,/*!*/ /*!*/);
  --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
  --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
  --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
          filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.blur {
  --tw-blur: blur(8px);
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.pypestream-loader > div {
  text-align: center;
  color: white;
  height: 8rem;
  width: 8rem;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.pypestream-loader > div > div:last-child {
  height: 72px;
  width: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: auto;
  position: absolute;
  top: -36px;
  left: 0;
  bottom: 0;
  right: 0;
}

.pypestream-loader > div > div:last-child path:last-child {
  fill: white;
}

@media (max-width: 1200px) {
  .smallScreen\:block {
    display: block;
  }

  .smallScreen\:hidden {
    display: none;
  }

  .smallScreen\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .smallScreen\:flex-col {
    flex-direction: column;
  }
}

@media (min-width: 1201px) {
  .desktopScreen\:block {
    display: block;
  }

  .desktopScreen\:flex {
    display: flex;
  }

  .desktopScreen\:hidden {
    display: none;
  }
}
  
/* purgecss start ignore */

html{
  font-family:"Open Sans",sans-serif !important;
  font-size:16px !important;
  height:100vh
}

body{
  width:auto;
  height:12vh;
  font-family:"Open Sans",sans-serif !important;
  margin:0;
  padding:0;
  -webkit-font-smoothing:antialiased;
  color:#29302e
}

#root{
  height:100vh;
  width:auto
}

.mt-0{
  margin-top:0
}

.text-core,p,a,h,h6,h4,h5,h2,h3,h1{
  color:#29302e;
  font-stretch:normal;
  font-style:normal;
  letter-spacing:normal
}

.text-gray{
  color:#716f6f
}

h1{
  font-size:26px;
  line-height:1.23;
  font-weight:normal
}

h2,h3{
  font-size:20px;
  line-height:1.2;
  font-weight:600;
  margin-top:0
}

h4,h5{
  font-size:18px;
  line-height:1.33;
  font-weight:600
}

h6{
  font-size:16px;
  line-height:1.5;
  font-weight:600
}

p,a{
  font-size:16px;
  line-height:1.5;
  font-weight:normal
}

p.body-header,a.body-header{
  font-weight:600
}

p.body-notation,a.body-notation{
  font-size:14px;
  font-style:italic;
  line-height:1.43;
  color:#7a6769
}

p.error,a.error{
  color:#e45f4f
}

a{
  color:#0a3ab4
}

a:hover{
  color:#062d9a;
  text-decoration:none
}

a:active{
  color:#042181;
  text-decoration:none
}

.manager .panel-heading{
  background-color:#f7f7f7;
  display:flex;
  justify-content:space-between;
  height:48px;
  font-size:20px;
  font-weight:500;
  color:#373a3c
}

.manager .panel-footer,.manager .breadcrumb{
  background-color:#eceeef
}

.manager .panel-footer{
  display:flex;
  justify-content:space-between
}

.manager .container-table-header{
  margin-top:24px;
  display:flex;
  align-items:center;
  justify-content:space-between
}

.manager .one-line{
  display:flex
}

.manager .description-line{
  font-size:16px;
  margin-top:12px;
  margin-bottom:25px;
  line-height:1.5
}

.manager .declaimer-line{
  height:56px;
  line-height:20px;
  border-radius:4px;
  border:solid 1px #bee5eb;
  background-color:#d1ecf1;
  font-size:16px;
  color:#0c5460;
  padding:16px
}

.manager .table{
  font-size:16px
}

.manager .glyphicon{
  position:static;
  font-size:12px
}

.manager .main-button{
  font-size:16px
}

.manager .btn-success{
  color:#fff;
  background-color:#28a745;
  border-color:#28a745
}

.manager .btn-primary{
  background-color:#0a3ab4;
  border-color:#0a3ab4
}

.manager .btn-secondary{
  background-color:#6c757d;
  border-color:#6c757d;
  color:#fff
}

.manager .btn-secondary[disabled]:hover{
  background-color:#b0b9c1;
  border-color:#b0b9c1;
  color:#fff
}

.manager .as-a-link{
  color:#337ab7;
  text-decoration:none
}

.manager .as-a-link:hover{
  cursor:pointer;
  color:#23527c;
  text-decoration:underline
}

.manager .s-alert-wrapper{
  margin-bottom:20px
}

.manager .s-alert-wrapper .alert{
  height:-webkit-fit-content;
  height:-moz-fit-content;
  height:fit-content;
  margin-bottom:0;
  padding:10px 23px;
  display:flex;
  justify-content:space-between;
  line-height:1.43
}

.manager .navbar{
  margin-bottom:0
}

.manager .tooltip-inner{
  background-color:#000;
  opacity:1;
  text-align:left;
  font-size:13px;
  padding:16px;
  border-radius:5px
}

.manager .env-version,.manager .capitalize{
  text-transform:capitalize;
  display:flex
}

.manager .table>tbody>tr>td{
  line-height:31px
}

.manager .buttons-container{
  display:flex;
  justify-content:flex-end
}

.manager .buttons-container>:not(:first-child){
  margin-left:8px
}

.manager .react-bs-container-body{
  min-height:200px
}

.manager .overview .react-bs-container-body{
  min-height:unset
}

.manager .pype-name{
  width:102px;
  height:18px;
  font-family:OpenSans;
  font-size:14px;
  font-weight:600;
  font-style:normal;
  font-stretch:normal;
  line-height:1.29;
  letter-spacing:normal;
  color:#373a3c
}

.manager .pype-description{
  width:305px;
  height:16px;
  font-family:OpenSans;
  font-size:12px;
  font-weight:normal;
  font-style:italic;
  font-stretch:normal;
  line-height:1.33;
  letter-spacing:normal;
  color:#d0021a
}

.logout-button{
  display:block;
  padding:3px 20px;
  clear:both;
  font-weight:400;
  line-height:1.42857143;
  color:#333;
  white-space:nowrap
}

.publish-modal .modal-dialog{
  max-width:610px;
  width:100%;
  border:solid 1px rgba(0,0,0,.2);
  border-radius:7px
}

.publish-modal .modal-dialog .modal-header{
  font-size:20px;
  font-weight:600;
  font-style:normal;
  font-stretch:normal;
  line-height:1;
  letter-spacing:normal;
  color:#373a3c;
  border-bottom:solid 1px rgba(0,0,0,.2);
  margin-bottom:32px
}

.publish-modal .modal-dialog .modal-header .version{
  width:70px
}

.publish-modal .modal-dialog .modal-header .btn{
  background-color:#eceeef;
  color:#373a3c
}

.publish-modal .modal-dialog .modal-header .btn:hover{
  box-shadow:none
}

.publish-modal .modal-dialog .modal-header .switch-btn .btn-default{
  width:auto;
  font-size:14px;
  font-weight:600;
  line-height:1.71;
  color:#043ac4;
  background:transparent;
  border:none
}

.publish-modal .modal-dialog .modal-header .switch-btn .btn-default i{
  font-size:12px;
  font-weight:normal;
  vertical-align:middle;
  margin-left:2px
}

.publish-modal .modal-dialog .modal-header .switch-btn .btn-default:hover{
  box-shadow:none
}

.publish-modal .modal-dialog .modal-body{
  font-size:14px;
  line-height:1.29;
  color:#373a3c;
  padding-bottom:32px
}

.publish-modal .modal-dialog .modal-body .btn-primary{
  height:auto;
  width:auto;
  padding:6px 12px;
  background-color:#043ac4;
  margin:10px 6px 0
}

.publish-modal .modal-dialog .modal-body .edit-btn{
  background-color:transparent;
  color:#043ac4;
  border-radius:4px;
  border:solid 1px #043ac4;
  font-style:normal
}

.publish-modal .modal-dialog .modal-footer{
  border-top:solid 1px rgba(0,0,0,.2);
  padding:13px
}

.publish-modal .modal-dialog .modal-footer .btn-info{
  background-color:transparent;
  width:110px;
  color:#043ac4;
  border-radius:4px;
  border:solid 1px #043ac4
}

.publish-modal .modal-dialog .modal-footer .btn-primary{
  background-color:#043ac4;
  width:110px
}

.publish-modal .modal-middle-content .control-label{
  text-transform:uppercase;
  color:#8a8a8a;
  font-size:12px;
  font-weight:500;
  line-height:1
}

.publish-modal .modal-middle-content .small-text{
  font-size:12px;
  font-style:italic;
  line-height:1.33;
  color:#696b77;
  padding-left:0;
  top:3px
}

.publish-modal .modal-middle-content .dropdown .dropdown-toggle{
  border:solid 1px #ccc;
  border-radius:4px;
  width:275px;
  font-size:16px;
  text-transform:capitalize;
  line-height:1.29;
  color:#373a3c;
  text-align:left;
  font-weight:400;
  padding:8px 10px;
  height:auto
}

.publish-modal .modal-middle-content .dropdown .dropdown-toggle .caret{
  border-top:6px dashed #000;
  border-right:5px solid transparent;
  border-left:5px solid transparent;
  border-radius:4px;
  margin-top:6px;
  float:right
}

.publish-modal .modal-middle-content .dropdown .dropdown-menu>li>a{
  width:273px
}

.publish-modal .show-all-btn .btn-default{
  width:auto;
  font-size:14px;
  font-weight:600;
  line-height:1.71;
  color:#043ac4;
  background:transparent;
  border:none;
  margin-left:-12px
}

.publish-modal .show-all-btn .btn-default i{
  font-size:12px;
  font-weight:normal;
  vertical-align:middle;
  margin-left:2px
}

.publish-modal .show-all-btn .btn-default:hover{
  box-shadow:none
}

.publish-modal .checkbox{
  display:inline-block;
  vertical-align:middle
}

.publish-modal .checkbox label{
  position:relative;
  padding:0;
  margin-right:8px;
  vertical-align:top
}

.publish-modal .checkbox label input[type=checkbox]{
  left:0;
  margin:0;
  top:0;
  width:100%;
  height:100%;
  opacity:0;
  z-index:2;
  cursor:pointer
}

.publish-modal .checkbox label .checkmark{
  border:2px solid #999;
  width:18px;
  height:18px;
  display:block
}

.publish-modal .checkbox label .checkmark:after{
  content:"";
  position:absolute;
  border-bottom:2px solid #043ac4;
  border-right:2px solid #043ac4;
  height:10px;
  width:7px;
  -webkit-transform:rotate(45deg);
  transform:rotate(45deg);
  left:6px;
  opacity:0;
  top:3px
}

.publish-modal .checkbox label input[type=checkbox]:checked+.checkmark:after{
  opacity:1
}

.pypes-list .row{
  border-radius:6px;
  margin:0 0 6px;
  transition:all .3s ease-in-out
}

.pypes-list .row:hover{
  background-color:#f4f4f4
}

.pypes-list .left .title_des,.pypes-list .right-column .title_des{
  display:inline-block;
  vertical-align:middle;
  width:85%
}

.pypes-list .left .title_des .pype-name,.pypes-list .right-column .title_des .pype-name{
  font-size:14px;
  text-transform:capitalize;
  font-weight:500;
  line-height:1.29;
  color:#373a3c;
  margin-bottom:0
}

.pypes-list .left .title_des .bot-name,.pypes-list .right-column .title_des .bot-name{
  font-size:18px;
  font-weight:600;
  line-height:2.29;
  color:#373a3c;
  margin:0
}

.pypes-list .left .title_des .pype-description,.pypes-list .right-column .title_des .pype-description{
  font-size:12px;
  line-height:1.33;
  color:#696b77;
  font-style:italic
}

.pypes-list .glyphicon{
  vertical-align:middle;
  color:#28a745;
  font-size:20px;
  padding-right:10px
}

.pypes-list .right{
  display:none;
  padding-top:7px
}

.pypes-list .right .radio{
  padding:0;
  margin:0
}

.pypes-list:hover .right{
  display:block
}

.radio{
  display:inline-block;
  vertical-align:middle;
  margin:0;
  padding:14px 0
}

.radio label{
  position:relative;
  padding:0;
  margin-right:8px;
  vertical-align:top
}

.radio label input[type=radio]{
  left:0;
  margin:0;
  top:0;
  width:100%;
  height:100%;
  opacity:0;
  z-index:2;
  cursor:pointer
}

.radio label .radiomark{
  display:block;
  font-size:12px;
  line-height:1.67;
  color:#696b77;
  padding-left:18px
}

.radio label .radiomark:before{
  content:"";
  position:absolute;
  left:0;
  top:3px;
  border:1px solid #696b77;
  width:12px;
  height:12px;
  border-radius:20px
}

.radio label .radiomark:after{
  content:"";
  position:absolute;
  height:8px;
  width:8px;
  border-radius:20px;
  opacity:0;
  background-color:#043ac4;
  left:2px;
  top:5px
}

.radio label input[type=radio]:checked+.radiomark:after{
  opacity:1
}

.radio+.radio{
  margin-top:0;
  margin-left:13px
}

.none-available .fa{
  vertical-align:middle;
  color:#d0021a;
  font-size:20px
}

.none-available .glyphicon{
  vertical-align:middle;
  color:#d0021a;
  font-size:20px;
  margin-left:10px
}

.none-available .none-left{
  display:inline-block;
  vertical-align:middle;
  margin-left:8px
}

.none-available .none-left h5{
  font-size:18px;
  font-weight:600;
  line-height:1.29;
  color:#373a3c;
  margin:0 0 5px
}

.none-available .none-left p,.none-available .none-left a{
  font-size:12px;
  font-weight:normal;
  font-style:italic;
  line-height:1.33;
  color:#d0021a;
  margin:0
}

.middle-content{
  max-height:310px;
  overflow:auto
}

.text-error--small{
  margin-top:8px;
  margin-left:16px;
  font-size:12px;
  color:#e45f4f;
  line-height:1
}

.bodyContainer{
  display:flex;
  margin:0 108px;
  padding-bottom:5rem
}

.bodyContainer .routeContainer{
  width:100%;
  width:-webkit-stretch;
  width:-webkit-fill-available;
  width:stretch
}

/* purgecss end ignore */
/* purgecss start ignore */

.pypestream-manager-loader{
  display:flex;
  justify-content:center;
  align-items:center;
  overflow:hidden
}

.pypestream-manager-loader.center{
  position:fixed;
  width:100%;
  height:100vh;
  top:50%;
  left:50%;
  -webkit-transform:translate(-50%, -50%);
  transform:translate(-50%, -50%)
}

.pypestream-manager-loader>div{
  min-width:128px;
  min-height:128px;
  height:128px;
  width:128px;
  position:relative
}

/* purgecss end ignore */
/* purgecss start ignore */

.previewPanel #pypestream-wrapper{
  height:calc(100vh - 80px) !important
}

/* purgecss end ignore */
/* purgecss start ignore */

#previewContainer{
  width:auto !important;
  height:calc(100vh - 5rem) !important
}

#previewContainer #pypestream-wrapper{
  width:auto !important;
  height:calc(100vh - 5rem) !important;
  position:absolute;
  left:0;
  margin-left:-0.79rem;
  margin-right:-0.19rem
}

/* purgecss end ignore */
