@import "./variables";

html {
  font-family: "Open Sans", sans-serif !important;
  font-size: 16px !important;
  height: 100vh;
}

body {
  width: auto;
  height: 12vh;
  font-family: "Open Sans", sans-serif !important;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  color: $text-primary;
}

#root {
  height: 100vh;
  width: auto;
}

.mt-0 {
  margin-top: 0;
}

.text-core {
  // Gets inherited by H1, H2, H3, H4, H5, and P
  color: $text-primary;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.text-gray {
  color: $color-placeholder;
}

h {
  @extend .text-core;

  &1 {
    @extend h;
    font-size: 26px;
    line-height: 1.23;
    font-weight: normal;
  }

  &2,
  &3 {
    @extend h;
    font-size: 20px;
    line-height: 1.2;
    font-weight: 600;
    margin-top: 0;
  }

  &4,
  &5 {
    @extend h;
    font-size: 18px;
    line-height: 1.33;
    font-weight: 600;
  }

  &6 {
    @extend h;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 600;
  }
}

p {
  @extend .text-core;
  font-size: 16px;
  line-height: 1.5;
  font-weight: normal;

  &.body-header {
    font-weight: 600;
  }

  &.body-notation {
    font-size: 14px;
    font-style: italic;
    line-height: 1.43;
    color: $text-gray;
  }

  &.error {
    color: #e45f4f;
  }
}

a {
  @extend p;
  color: $color-primary;

  &:hover {
    color: $color-primary-600;
    text-decoration: none;
  }

  &:active {
    color: $color-primary-700;
    text-decoration: none;
  }
}

.manager {
  .panel-heading {
    background-color: #f7f7f7;
    display: flex;
    justify-content: space-between;
    height: 48px;
    font-size: 20px;
    font-weight: 500;
    color: #373a3c;
  }

  .panel-footer,
  .breadcrumb {
    background-color: #eceeef;
  }

  .panel-footer {
    display: flex;
    justify-content: space-between;
  }

  .container-table-header {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .one-line {
    display: flex;
  }

  .description-line {
    font-size: 16px;
    margin-top: 12px;
    margin-bottom: 25px;
    line-height: 1.5;
  }

  .declaimer-line {
    height: 56px;
    line-height: 20px;
    border-radius: 4px;
    border: solid 1px #bee5eb;
    background-color: #d1ecf1;
    font-size: 16px;
    color: #0c5460;
    padding: 16px;
  }
  .table {
    font-size: 16px;
  }

  .glyphicon {
    position: static;
    font-size: 12px;
  }

  .main-button {
    font-size: 16px;
  }

  .btn-success {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
  }

  .btn-primary {
    background-color: $color-primary;
    border-color: $color-primary;
  }

  .btn-secondary {
    background-color: #6c757d;
    border-color: #6c757d;
    color: white;
  }
  .btn-secondary[disabled]:hover {
    background-color: #b0b9c1;
    border-color: #b0b9c1;
    color: white;
  }

  .as-a-link {
    color: #337ab7;
    text-decoration: none;
  }

  .as-a-link:hover {
    cursor: pointer;
    color: #23527c;
    text-decoration: underline;
  }

  .s-alert-wrapper {
    // min-height: 62px;
    // padding: 10px 0;
    margin-bottom: 20px;
  }
  .s-alert-wrapper .alert {
    height: fit-content;
    margin-bottom: 0;
    padding: 10px 23px;
    display: flex;
    justify-content: space-between;
    line-height: 1.43;
  }
  .navbar {
    margin-bottom: 0;
  }

  .tooltip-inner {
    background-color: black;
    opacity: 1;
    text-align: left;
    font-size: 13px;
    padding: 16px;
    border-radius: 5px;
  }
  .env-version,
  .capitalize {
    text-transform: capitalize;
    display: flex;
  }
  .table > tbody > tr > td {
    line-height: 31px;
  }

  .buttons-container {
    display: flex;
    justify-content: flex-end;
  }

  .buttons-container > :not(:first-child) {
    margin-left: 8px;
  }

  .react-bs-container-body {
    min-height: 200px;
  }

  .overview .react-bs-container-body {
    min-height: unset;
  }

  .pype-name {
    width: 102px;
    height: 18px;
    font-family: OpenSans;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #373a3c;
  }

  .pype-description {
    width: 305px;
    height: 16px;
    font-family: OpenSans;
    font-size: 12px;
    font-weight: normal;
    font-style: italic;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #d0021a;
  }
}

.logout-button {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}

.publish-modal {
  .modal-dialog {
    max-width: 610px;
    width: 100%;
    border: solid 1px rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    .modal-header {
      font-size: 20px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #373a3c;
      border-bottom: solid 1px rgba(0, 0, 0, 0.2);
      margin-bottom: 32px;
      .version {
        width: 70px;
      }
      .btn {
        background-color: #eceeef;
        color: #373a3c;
        &:hover {
          box-shadow: none;
        }
      }
      .switch-btn .btn-default {
        width: auto;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.71;
        color: #043ac4;
        background: transparent;
        border: none;
        i {
          font-size: 12px;
          font-weight: normal;
          vertical-align: middle;
          margin-left: 2px;
        }
        &:hover {
          box-shadow: none;
        }
      }
    }
    .modal-body {
      font-size: 14px;
      line-height: 1.29;
      color: #373a3c;
      padding-bottom: 32px;
      .btn-primary {
        height: auto;
        width: auto;
        padding: 6px 12px;
        background-color: #043ac4;
        margin: 10px 6px 0;
      }
      .edit-btn {
        background-color: transparent;
        color: #043ac4;
        border-radius: 4px;
        border: solid 1px #043ac4;
        font-style: normal;
      }
    }
    .modal-footer {
      border-top: solid 1px rgba(0, 0, 0, 0.2);
      padding: 13px;
      .btn-info {
        background-color: transparent;
        width: 110px;
        color: #043ac4;
        border-radius: 4px;
        border: solid 1px #043ac4;
      }
      .btn-primary {
        background-color: #043ac4;
        width: 110px;
      }
    }
  }
  .modal-middle-content {
    .control-label {
      text-transform: uppercase;
      color: #8a8a8a;
      font-size: 12px;
      font-weight: 500;
      line-height: 1;
    }
    .small-text {
      font-size: 12px;
      font-style: italic;
      line-height: 1.33;
      color: #696b77;
      padding-left: 0;
      top: 3px;
    }
    .dropdown {
      .dropdown-toggle {
        border: solid 1px #cccccc;
        border-radius: 4px;
        width: 275px;
        font-size: 16px;
        text-transform: capitalize;
        line-height: 1.29;
        color: #373a3c;
        text-align: left;
        font-weight: 400;
        padding: 8px 10px;
        height: auto;
        .caret {
          border-top: 6px dashed #000;
          border-right: 5px solid transparent;
          border-left: 5px solid transparent;
          border-radius: 4px;
          margin-top: 6px;
          float: right;
        }
      }
      .dropdown-menu > li > a {
        width: 273px;
      }
    }
  }
  .show-all-btn .btn-default {
    width: auto;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.71;
    color: #043ac4;
    background: transparent;
    border: none;
    margin-left: -12px;
    i {
      font-size: 12px;
      font-weight: normal;
      vertical-align: middle;
      margin-left: 2px;
    }
    &:hover {
      box-shadow: none;
    }
  }
  .checkbox {
    display: inline-block;
    vertical-align: middle;
    label {
      position: relative;
      padding: 0;
      margin-right: 8px;
      vertical-align: top;
      input[type="checkbox"] {
        left: 0;
        margin: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 2;
        cursor: pointer;
      }
      .checkmark {
        border: 2px solid #999999;
        width: 18px;
        height: 18px;
        display: block;
        &:after {
          content: "";
          position: absolute;
          border-bottom: 2px solid #043ac4;
          border-right: 2px solid #043ac4;
          height: 10px;
          width: 7px;
          transform: rotate(45deg);
          left: 6px;
          opacity: 0;
          top: 3px;
        }
      }
      input[type="checkbox"]:checked + .checkmark:after {
        opacity: 1;
      }
    }
  }
}
.pypes-list {
  .row {
    border-radius: 6px;
    margin: 0 0 6px;
    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: #f4f4f4;
    }
  }
  .left,
  .right-column {
    .title_des {
      display: inline-block;
      vertical-align: middle;
      width: 85%;
      .pype-name {
        font-size: 14px;
        text-transform: capitalize;
        font-weight: 500;
        line-height: 1.29;
        color: #373a3c;
        margin-bottom: 0;
      }
      .bot-name {
        font-size: 18px;
        font-weight: 600;
        line-height: 2.29;
        color: #373a3c;
        margin: 0;
      }
      .pype-description {
        font-size: 12px;
        line-height: 1.33;
        color: #696b77;
        font-style: italic;
      }
    }
  }
  .glyphicon {
    vertical-align: middle;
    color: #28a745;
    font-size: 20px;
    padding-right: 10px;
  }
  .right {
    display: none;
    padding-top: 7px;
    .radio {
      padding: 0;
      margin: 0;
    }
  }
  &:hover {
    .right {
      display: block;
    }
  }
}

.radio {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  padding: 14px 0;
  label {
    position: relative;
    padding: 0;
    margin-right: 8px;
    vertical-align: top;
    input[type="radio"] {
      left: 0;
      margin: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 2;
      cursor: pointer;
    }
    .radiomark {
      display: block;
      font-size: 12px;
      line-height: 1.67;
      color: #696b77;
      padding-left: 18px;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 3px;
        border: 1px solid #696b77;
        width: 12px;
        height: 12px;
        border-radius: 20px;
      }
      &:after {
        content: "";
        position: absolute;
        height: 8px;
        width: 8px;
        border-radius: 20px;
        opacity: 0;
        background-color: #043ac4;
        left: 2px;
        top: 5px;
      }
    }
    input[type="radio"]:checked + .radiomark:after {
      opacity: 1;
    }
  }
}
.radio + .radio {
  margin-top: 0;
  margin-left: 13px;
}
.none-available {
  .fa {
    vertical-align: middle;
    color: #d0021a;
    font-size: 20px;
  }
  .glyphicon {
    vertical-align: middle;
    color: #d0021a;
    font-size: 20px;
    margin-left: 10px;
  }
  .none-left {
    display: inline-block;
    vertical-align: middle;
    margin-left: 8px;
    h5 {
      font-size: 18px;
      font-weight: 600;
      line-height: 1.29;
      color: #373a3c;
      margin: 0 0 5px;
    }
    p {
      font-size: 12px;
      font-weight: normal;
      font-style: italic;
      line-height: 1.33;
      color: #d0021a;
      margin: 0;
    }
  }
}
.middle-content {
  max-height: 310px;
  overflow: auto;
}

.text-error {
  &--small {
    margin-top: 8px;
    margin-left: 16px;
    font-size: 12px;
    color: $otherError500;
    line-height: 1;
  }
}

.bodyContainer {
  display: flex;
  margin: 0 108px;
  padding-bottom: 5rem;

  .routeContainer {
    width: 100%;
    width: -moz-available;
    width: -webkit-stretch;
    width: stretch;
  }
}
