#previewContainer {
  width: auto !important;
  height: calc(100vh - 5rem) !important;

  #pypestream-wrapper {
    width: auto !important;
    height: calc(100vh - 5rem) !important;
    position: absolute;
    left: 0;
    margin-left: -0.79rem;
    margin-right: -0.19rem;
  }
}
