.pypestream-manager-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  &.center {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  & > div {
    min-width: 128px;
    min-height: 128px;
    height: 128px;
    width: 128px;
    position: relative;
  }
}
